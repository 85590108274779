section {
  padding: 4.5rem 1rem 0;
  font-weight: 300;
}
section h2 {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: .7rem;
  text-transform: uppercase;
}
section p {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: .6rem;
}

.devicons-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: .6rem;
}
.devicons-list img {
  width: 2rem;
  height: 2rem;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}
.devicons-list img.invert {
  filter: invert(80%) drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}
.btn {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  padding: .5rem 1rem;
  color: #fff;
  border: 0;
  border-radius: .2rem;
  transition: all .1s linear;
}
.btn:hover {
  cursor: pointer;
  filter: brightness(1.4);
}
.btn:active {
  filter: brightness(.8);
}
.btn-primary {
  background-color: #1890ec;
}

ul.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem auto 0;
}
ul.cards li {
  margin-bottom: 2rem;
  flex: 0 1;
  min-height: 350px;
}
ul.cards img.thumbnail {
  width: 80vw;
  border: .1rem solid #353535;
}

ul.cards div {
  padding: .5rem
}
ul.cards div h5 {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: .2rem;
}
ul.cards div p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

ul.cards div.tags {
  padding: 0;
}
.tags img {
  margin: 0 .5rem 0 0;
  width: 1.3rem;
  height: 1.3rem;
}

.loading-spinner {
  width: 3rem;
  height: 3rem;
  border: .1rem solid transparent;
  border-bottom-color: #DDD;
  border-radius: 50%;
  margin: 1rem auto;
  animation: spin .5s linear infinite;
}


@media (min-width: 600px) {
  ul.cards {
    justify-content: space-between;
  }
  ul.cards img.thumbnail {
    width: 40vw;
  }
}
@media (min-width: 900px) {
  ul.cards img.thumbnail {
    width: 25vw;
  }
}
@media (min-width: 1200px) {
  ul.cards img.thumbnail {
    width: 20vw;
  }
}


/* ABOUT */
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}




/* Media Breakpoints */

@media (min-width: 900px) {
  .box {
    width: 40%;
  } 
}

@media (min-width: 900px) {
  .col {
    width: 50vw;
  }
  .row {
    flex-direction: row;
    justify-content: space-between;
  } 
  .items {
    font-size: .7rem;
    align-items: center;
    width: 40vw;
  }
  .box {
    padding: 1rem 0;
  }
  .box i {
    font-size: 1.3rem;
    margin: .1rem;
  }
}
@media (min-width: 1200px) {
  
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}