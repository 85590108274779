@import '../../vars.css';

header {
  background-color: var(--dark-900);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  position: fixed;
  z-index: 99;
  width: 100vw;
  box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
}
nav ul {
  display: flex;
  text-transform: uppercase;
  font-size: .75rem;
}
nav ul a {
  display: block;
  padding: 1.2rem;
  border-bottom: 2px solid transparent;
  transition: all .3s;
}
nav ul a:hover {
  background-color: var(--dark-800);
  filter: brightness(1.4);
}
nav ul a.active {
  background-color: var(--dark-800);
}
nav ul a.active {
  border-color: #1890ec;
}
@media (max-width: 600px) {
  header h1, header nav {
    visibility: hidden;
  }
}
