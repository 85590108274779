@import '../../vars.css';
.logo {
  text-transform: uppercase;
  font-size: 1rem;
}
.logo span {
  border-radius: .2rem;
  background-color: var(--light-blue);
  padding: .3rem;
  line-height: 1rem;
  color: var(--blue);
  margin-right: .3rem;
}