@import '../../vars.css';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100;
}
menu {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60vw;
  background-color: var(--dark-900);
  transform: translateX(-101%);
  transition: all .3s ease-out;
}
menu.open {
  transform: translateX(0);
}
menu h1 {
  margin: 1.1rem .5rem;
}
menu nav ul a {
  padding: 1rem .5rem;
}
.closeButton {
  background: none;
  cursor: pointer;
  border: 0;
  color: var(--foreground);
  position: absolute;
  font-size: 1.2rem;
  padding: 1rem;
}
menu .closeButton {
  top: 0;
  right: -2.9rem;
  background-color: var(--dark-900);
}
@media (min-width: 500px) {
  menu {
    width: 40vw;
  }
}
@media (min-width: 600px) {
  menu .closeButton {
    display: none;
  }
}

menu nav ul {
  flex-direction: column;
}
menu nav ul a.active {
  background-color: #444;
}
menu nav ul a.active {
  border-color: #1890ec;
}