@import '../../vars.css';

.card {
  padding: 1rem;
  background-color: var(--dark-900);
  border-radius: .5rem;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
  animation: appear .5s ease-in;
}
@keyframes appear {
  from { 
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}