@import './vars.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}


ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--foreground);
}

body {
  background-color: var(--bg-dark);
  color: var(--foreground);
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::selection {
  background: var(--dark-900);
}
button:active, a:active {
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}