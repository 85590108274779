@import '../../vars.css';

.box {
  padding: 1.5rem;
  text-align: center;
  animation: appear .5s ease-in;
}
.box h2 {
  margin: .2rem 0 .4rem;
}
.fa {
  font-size: 1.6rem;
  color: #656565;
}
.box a {
  display: inline-flex;
  align-items: center;
  transition: all .1s linear;
  font-size: 1rem;
}
.box a strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  margin-left: .5rem;
}
.box a:hover {
  transform: translateY(-.1rem) scale(1.2);
}
.box a img {
  width: 1rem;
  height: 1rem;
}
@keyframes appear {
  from { 
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




